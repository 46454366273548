const React = require('react');
const PropTypes = require('prop-types');

function Pix({ className = '' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 56 19"
      className={className}
    >
      <path
        fill="#8A8B8A"
        fillRule="evenodd"
        d="M22.358 18.567a.357.357 0 01-.358-.356V7.201a4.219 4.219 0 014.215-4.215l3.42.005a4.214 4.214 0 014.2 4.21v2.858a4.219 4.219 0 01-4.215 4.214h-5.367a.357.357 0 110-.713h5.367c1.93 0 3.503-1.57 3.503-3.5V7.2a3.501 3.501 0 00-3.49-3.496L26.215 3.7c-1.93 0-3.5 1.57-3.5 3.501v11.01c0 .198-.16.357-.357.357zM37.5 14.325a.357.357 0 01-.357-.357V4.924c0-.676-.55-1.227-1.227-1.227h-1.483a.357.357 0 110-.713h1.483c1.07 0 1.94.87 1.94 1.94v9.044c0 .197-.16.357-.356.357"
        clipRule="evenodd"
      />
      <path
        fill="#38B39F"
        fillRule="evenodd"
        d="M37.182 2.076l-.672-.673a.429.429 0 010-.604l.672-.673a.428.428 0 01.605 0L38.46.8a.427.427 0 010 .604l-.673.673a.429.429 0 01-.604 0z"
        clipRule="evenodd"
      />
      <path
        fill="#8A8B8A"
        fillRule="evenodd"
        d="M41.798 14.273h-1.47a.357.357 0 110-.713h1.47c.667 0 1.294-.26 1.767-.73l3.44-3.377a1.493 1.493 0 012.118 0l3.428 3.363c.47.472 1.097.732 1.764.732h1.197a.356.356 0 010 .713h-1.197a3.19 3.19 0 01-2.27-.94l-3.427-3.364a.783.783 0 00-1.11 0l-3.44 3.376a3.189 3.189 0 01-2.27.94zm0-11.289h-1.47a.357.357 0 000 .713h1.47c.667 0 1.294.26 1.767.731l3.44 3.377c.282.283.658.438 1.058.438s.776-.155 1.06-.438l3.428-3.364a2.474 2.474 0 011.764-.73h1.197a.356.356 0 000-.714h-1.197a3.19 3.19 0 00-2.27.94L48.618 7.3a.783.783 0 01-1.11 0l-3.44-3.376a3.189 3.189 0 00-2.27-.94"
        clipRule="evenodd"
      />
      <path
        fill="#38B39F"
        fillRule="evenodd"
        d="M4.06 14.112a2.7 2.7 0 001.918-.793l2.767-2.767a.526.526 0 01.727 0l2.776 2.777a2.693 2.693 0 001.917.793h.545l-3.505 3.505a2.803 2.803 0 01-3.963 0l-3.514-3.516h.333v.001zm10.104-9.786c-.723 0-1.405.282-1.916.793L9.472 7.897c-.2.2-.527.2-.727 0L5.978 5.128a2.696 2.696 0 00-1.917-.793h-.333L7.242.821a2.801 2.801 0 013.964 0l3.504 3.504h-.546"
        clipRule="evenodd"
      />
      <path
        fill="#38B39F"
        fillRule="evenodd"
        d="M.82 7.243L2.916 5.15H4.06c.498 0 .987.203 1.34.556l2.768 2.766a1.33 1.33 0 001.877 0l2.777-2.777a1.908 1.908 0 011.34-.556h1.36l2.104 2.104a2.803 2.803 0 010 3.963l-2.104 2.103h-1.358c-.5 0-.988-.202-1.34-.555l-2.778-2.777c-.503-.502-1.378-.502-1.879 0L5.4 12.744a1.908 1.908 0 01-1.34.556H2.913L.82 11.206a2.803 2.803 0 010-3.963z"
        clipRule="evenodd"
      />
    </svg>
  );
}

Pix.propTypes = {
  className: PropTypes.string,
};

module.exports = Pix;
