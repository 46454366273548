const React = require('react');
const PropTypes = require('prop-types');

const Icon = require('../../../../../components/Icon');

function LegalEmail({ href, text, icon }) {
  return (
    <div className="footer__legal-email">
      {icon && <Icon name={icon} className="footer__legal-email__icon" />}
      <a href={href} className="footer__legal-email__link">
        {text}
      </a>
    </div>
  );
}

LegalEmail.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

module.exports = LegalEmail;
