/* istanbul ignore file */
/* eslint-disable security/detect-object-injection */
const React = require('react');
const PropTypes = require('prop-types');

const icons = require('./icons');

const Icon = ({ name = '', className = '' }) => {
  /* istanbul ignore next */
  if (!icons[name]) return null;
  const RenderIcon = icons[name];
  return (
    <div className={className}>
      <RenderIcon className={`${name}-icon`} />
    </div>
  );
};

Icon.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
};

module.exports = Icon;
