const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');

const HubButton = ({ data, href, text, className = '', children = null }) => (
  <a
    className={classNames('hub-button', `${className}`)}
    role="button"
    href={href}
    data-category={data.analytics.category}
    data-action={data.analytics.action}
    data-label={data.analytics.label}
    data-id={data.melidata.id}
  >
    {children}
    {text}
  </a>
);

HubButton.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  data: PropTypes.shape({
    analytics: PropTypes.shape({
      category: PropTypes.string,
      action: PropTypes.string,
      label: PropTypes.string,
    }),
    melidata: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
};

module.exports = HubButton;
