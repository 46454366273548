const React = require('react');
const PropTypes = require('prop-types');
const { LINK_TYPE } = require('../../common');
const Button = require('../../../../../components/Button');

const CancelPrdt = ({ cancelPrdt }) => (
  <div className="footer__pnfc-wrapper" data-testid="pnfc-container">
    <p className="footer__content footer__pnfc-text-link">
      {cancelPrdt.mpLink.href ? (
        <a href={cancelPrdt.mpLink.href}>{cancelPrdt.mpLink.text}</a>
      ) : (
        cancelPrdt.mpLink.text
      )}
    </p>
    <Button
      text={cancelPrdt.button.text}
      href={cancelPrdt.button.href}
      className="cancelPrdt-button"
    />
  </div>
);

CancelPrdt.propTypes = {
  cancelPrdt: PropTypes.shape({
    mpLink: LINK_TYPE,
    button: PropTypes.shape({
      text: PropTypes.string,
      href: PropTypes.string,
    }),
  }),
};

module.exports = CancelPrdt;
