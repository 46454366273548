const { useState } = require('react');
const SMSMessages = require('../../data/SmsMessages');
const {
  isMinLengthValid,
  numberIsValid,
  parseInteger,
} = require('../../utils/phoneValidation');

const useEventsHandler = (phoneValidations, siteId, onSend) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [inputMessage, setInputMessage] = useState({
    modifier: undefined,
    message: '',
  });
  const [button, setButton] = useState('Enviar SMS');
  const [disabledButton, setDisabledButton] = useState(false);
  // eslint-disable-next-line security/detect-object-injection
  const siteMessages = SMSMessages[siteId];
  const { countryCode } = siteMessages;

  const validatePhoneNumber = () => {
    if (!isMinLengthValid(phoneNumber, phoneValidations)) {
      /* istanbul ignore next */
      setInputMessage({
        modifier: 'error',
        message: `${siteMessages.minPhoneNumberLength.digite} ${phoneValidations.minPhoneNumberLength} ${siteMessages.minPhoneNumberLength.caracteres}`,
      });
      /* istanbul ignore next */
      return false;
    }
    if (!numberIsValid(phoneNumber, phoneValidations)) {
      /* istanbul ignore next */
      setInputMessage({
        modifier: 'error',
        message: siteMessages.invalidNumber,
      });
      /* istanbul ignore next */
      return false;
    }
    return true;
  };
  /* istanbul ignore next */
  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
    }
    if (validatePhoneNumber()) {
      setInputMessage({
        modifier: 'indeterminate',
        message: '',
      });
      /**
       * En la aplicación donde se implemente el componente SmsSender es necesario definir una promise que realice un
       * fetch a la API de SMS Wrapper para resolver el envío del SMS. La respuesta se procesa en esta función para
       * mostrar al usuario el mensaje informativo correspondiente según el resultado.
       */
      onSend({ phoneNumber, countryCode })
        .then(({ data }) => {
          if (data.success) {
            setDisabledButton(true);
            setInputMessage({
              modifier: 'completed',
              message: siteMessages.successMsg,
            });
          } else {
            setButton('Reenviar SMS');
            setInputMessage({
              modifier: 'error',
              message: siteMessages.errorMsg,
            });
          }
        })
        .catch(() => {
          setButton('Reenviar SMS');
          setInputMessage({
            modifier: 'error',
            message: siteMessages.errorMsg,
          });
        });
    }
  };

  const handleChange = (event) => {
    event.persist();
    const newPhoneNumber = event.target.value;
    if (
      phoneNumber !== newPhoneNumber &&
      inputMessage.modifier !== 'indeterminate'
    ) {
      const number = parseInteger(newPhoneNumber);
      setDisabledButton(false);
      setPhoneNumber(number);
      setInputMessage({ modifier: undefined, message: '' });
    }
  };

  return {
    phoneNumber,
    inputMessage,
    button,
    disabledButton,
    handleSubmit,
    handleChange,
  };
};

module.exports = useEventsHandler;
