/**
 * Module dependencies
 */
const React = require('react');
const { Script } = require('nordic/script');

const Tracking = () => (
  <Script>
    {`
      if (typeof melidata == 'function') {
        document.addEventListener('click', function (event) {
          var elementData = document.activeElement.dataset;
          if (!elementData || !elementData.id) return;
          melidata('send', 'event', {
            path: '/landing/home/click',
            event_data: {
              id: elementData.id,
            }
          });
        }, false);
      }
      `}
  </Script>
);

module.exports = Tracking;
