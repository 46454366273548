const React = require('react');
const PropTypes = require('prop-types');
const Icons = require('./Icons');

const Banner = ({ icon = '', message = '', link = null }) => (
  <div className="banner">
    {icon && <Icons name={icon} className="banner__icon" />}
    <p className="banner__text">
      {message} {link && <a href={link.href}>{link.text}</a>}
    </p>
  </div>
);

Banner.propTypes = {
  icon: PropTypes.string,
  message: PropTypes.string,
  link: PropTypes.shape({
    text: PropTypes.string,
    href: PropTypes.string,
  }),
};

module.exports = Banner;
