/**
 * Module dependencies
 */
const React = require('react');
const { hydrateRoot } = require('nordic/hydrate');
const { AndesProvider } = require('@andes/context');

/**
 * Nordic dependencies
 */
const I18n = require('nordic/i18n');
const I18nProvider = require('nordic/i18n/I18nProvider');

/**
 * View
 */
const View = require('../pages/main/view');

/**
 * Get server state
 */
const state = window.__PRELOADED_STATE__;

/**
 * i18n
 */
const i18n = new I18n({
  translations: state.translations,
});

/**
 * Mount View on client
 */
hydrateRoot(
  document.getElementById('root-app'),
  <AndesProvider
    locale={state.head.metaData.locale}
    i18n={i18n}
    csrfToken={state.contextData.csrfToken}
  >
    <I18nProvider i18n={i18n}>
      <View {...state} />
    </I18nProvider>
  </AndesProvider>
);
