module.exports = {
  MLA: {
    countryFlag: 'Bandera de Argentina',
    countryCode: '54',
    placeholder: 'Cód. área + número',
    minPhoneNumberLength: {
      digite: 'Ingresá al menos',
      caracteres: 'caracteres.',
    },
    invalidNumber: 'Ingrese un teléfono válido.',
    errorMsg: 'No pudimos enviar el SMS.',
    successMsg: '¡Listo! Revisá tu celular para ver el link de descarga.',
  },
  MLB: {
    countryFlag: 'Bandeira do brasil',
    countryCode: '55',
    placeholder: 'DDD + número',
    minPhoneNumberLength: {
      digite: 'Digite pelo menos',
      caracteres: 'caracteres.',
    },
    invalidNumber: 'Digite um telefone válido.',
    errorMsg: 'Não foi possível enviar o SMS.',
    successMsg: 'Pronto! Confira o seu celular para ver o link para baixar.',
  },
  MLM: {
    countryFlag: 'Bandera de México',
    countryCode: '52',
    placeholder: 'Cód. área + número',
    minPhoneNumberLength: {
      digite: 'Ingresa al menos',
      caracteres: 'caracteres.',
    },
    invalidNumber: 'Ingrese un teléfono válido.',
    errorMsg: 'No pudimos enviar el SMS.',
    successMsg: '¡Listo! Revisa tu celular para ver el link de descarga.',
  },
  MLC: {
    countryFlag: 'Bandera de Chile',
    countryCode: '56',
    placeholder: 'Cód. área + número',
    minPhoneNumberLength: {
      digite: 'Ingresa al menos',
      caracteres: 'caracteres.',
    },
    invalidNumber: 'Ingrese un teléfono válido.',
    errorMsg: 'No pudimos enviar el SMS.',
    successMsg: '¡Listo! Revisa tu celular para ver el link de descarga.',
  },
  MLU: {
    countryFlag: 'Bandera de Uruguay',
    countryCode: '598',
    placeholder: 'Cód. área + número',
    minPhoneNumberLength: {
      digite: 'Ingresá al menos',
      caracteres: 'caracteres.',
    },
    invalidNumber: 'Ingrese un teléfono válido.',
    errorMsg: 'No pudimos enviar el SMS.',
    successMsg: '¡Listo! Revisá tu celular para ver el link de descarga.',
  },
  MCO: {
    countryFlag: 'Bandera de Colombia',
    countryCode: '57',
    placeholder: 'Cód. área + número',
    minPhoneNumberLength: {
      digite: 'Ingresa al menos',
      caracteres: 'caracteres.',
    },
    invalidNumber: 'Ingrese un teléfono válido.',
    errorMsg: 'No pudimos enviar el SMS.',
    successMsg: '¡Listo! Revisa tu celular para ver el link de descarga.',
  },
  MPE: {
    countryFlag: 'Bandera de Perú',
    countryCode: '51',
    placeholder: 'Cód. área + número',
    minPhoneNumberLength: {
      digite: 'Ingresa al menos',
      caracteres: 'caracteres.',
    },
    invalidNumber: 'Ingrese un teléfono válido.',
    errorMsg: 'No pudimos enviar el SMS.',
    successMsg: '¡Listo! Revisa tu celular para ver el link de descarga.',
  },
};
