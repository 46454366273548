const React = require('react');
const PropTypes = require('prop-types');

const Button = ({ href, text, className = '', children = null }) => (
  <a role="button" href={href} className={`${className}`}>
    {children}
    {text}
  </a>
);

Button.propTypes = {
  text: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
};

module.exports = Button;
