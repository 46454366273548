/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');
const { Head } = require('nordic/head');
const { Style } = require('nordic/style');
const MeliGA = require('nordic/analytics/meli-ga');
const { MelidataTrack } = require('nordic/melidata/melidata-track');
const { Script } = require('nordic/script');
const classNames = require('classnames');
const { GTM } = require('nordic/gtm');

/**
 * landings-library Components
 */

const {
  googleCaptcha: { SITE_KEY },
} = require('../../constants');

// AppContext
const AppContext = require('../../AppContext');

/**
 * Components
 */
const Tracking = require('../../components/Tracking');
const Konami = require('../../components/Konami');
const Hero = require('../../components/Hero');
const Banner = require('../../components/Banner');
const Content = require('./Content');
const Footer = require('./Footer');
const StickyButton = require('../../components/StickyButton');

/**
 * View Component Using Page.
 */
const View = ({
  siteId,
  contextData,
  GAdimensions = {},
  gtmId,
  head,
  sections = {
    hero: {
      title: 'Título',
      description: 'Descripción',
    },
  },
  translations,
}) => (
  <AppContext.Provider value={contextData}>
    <Head>
      <title>{head.title || ''}</title>

      <link
        rel="preload"
        href="https://http2.mlstatic.com/ui/webfonts/v4.2.0/proxima-nova/proxima-nova-800.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />

      <title>{head.title || ''}</title>
      <meta name="description" content={head.description || ''} />
      <meta property="og:url" content={head.metaData.url || ''} />
      <meta property="og:locale" content={head.metaData.locale || ''} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={head.title} />
      <meta property="og:description" content={head.description} />
      <meta property="og:site_name" content={head.metaData.siteName || ''} />
      <meta property="og:image" content={head.metaData.image || ''} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={head.metaData.twitterAccount || ''} />
      <meta name="twitter:title" content={head.title} />
      <meta name="twitter:description" content={head.description} />
      <meta name="twitter:image" content={head.metaData.image || ''} />

      <link
        rel="alternate"
        href={head.metaData.url || ''}
        hrefLang={head.metaData.locale || ''}
      />
      <link
        rel="alternate"
        href="https://www.mercadopago.com"
        hrefLang="x-default"
      />
    </Head>
    <div
      id="mp-home"
      className={classNames(
        'mp-home',
        `mp-home--${siteId.toLowerCase()}`,
        { webp: contextData.browserSupportWebp },
        { 'no-webp': !contextData.browserSupportWebp }
      )}
    >
      <MeliGA section="HOME" page="INDEX" dimensions={GAdimensions} />
      <MelidataTrack path="/landing/home" />
      <Style href={`critical.styles.${contextData.deviceType}.css`} inline />
      <Style
        href={`${siteId.toLowerCase()}.home.${contextData.deviceType}.css`}
      />

      {sections.banner && <Banner {...sections.banner} />}
      {sections.hero && <Hero {...sections.hero} />}
      {sections.content && <Content {...sections.content} />}
      {sections.footer && <Footer {...sections.footer} />}
      <StickyButton {...sections.stickybutton} />

      <Script>
        {`
          // preload state
          window.__PRELOADED_STATE__ = ${JSON.stringify({
            siteId,
            contextData,
            GAdimensions,
            gtmId,
            head,
            sections,
            translations,
          })};

          // aload.js
          function aload(t){"use strict";var e="data-aload";return t=t||window.document.querySelectorAll("["+e+"]"),
          void 0===t.length&&(t=[t]),[].forEach.call(t,function(t){t["LINK"!==t.tagName?"src":"href"]
          =t.getAttribute(e),t.removeAttribute(e)}),t}
          aload();
          `}
      </Script>
      {siteId === 'MLA' && contextData.deviceType === 'desktop' && (
        <Script>
          {`
              // google captcha
              function loadCaptchaScript() {
                var captchaScript = document.createElement('script');
                captchaScript.src="https://www.google.com/recaptcha/enterprise.js?render=${SITE_KEY}";
                captchaScript.setAttribute("defer", "defer");
                captchaScript.setAttribute("async", "async");
                document.body.appendChild(captchaScript);
              }
              loadCaptchaScript();
              `}
        </Script>
      )}
      <Tracking />
      <Konami />
      <GTM id={gtmId} />
      {contextData.deviceType === 'desktop' && (
        <div>
          <Script
            src={`${siteId.toLowerCase()}.home.${contextData.deviceType}.js`}
          />
        </div>
      )}
    </div>
  </AppContext.Provider>
);

View.propTypes = {
  siteId: PropTypes.string.isRequired,
  contextData: PropTypes.shape({
    deviceType: PropTypes.string,
    deviceOutdated: PropTypes.bool,
    browserSupportWebp: PropTypes.bool,
    csrfToken: PropTypes.string,
  }).isRequired,
  GAdimensions: PropTypes.shape({}),
  gtmId: PropTypes.string.isRequired,
  head: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    metaData: PropTypes.shape({
      url: PropTypes.string,
      hreflang: PropTypes.string,
      locale: PropTypes.string,
      siteName: PropTypes.string,
      image: PropTypes.string,
      twitterAccount: PropTypes.string,
    }),
  }).isRequired,
  sections: PropTypes.shape({
    banner: PropTypes.shape({}),
    hero: PropTypes.shape({}),
    content: PropTypes.shape({}),
    footer: PropTypes.shape({}),
    incentive: PropTypes.shape({}),
    stickybutton: PropTypes.shape({}),
  }),
  translations: PropTypes.shape({}).isRequired,
};

/**
 * View.
 */
module.exports = View;
