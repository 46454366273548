const React = require('react');
const PropTypes = require('prop-types');

const googlePlayStore = ({ className = '' }) => (
  <svg fill="none" viewBox="0 0 20 24" className={className}>
    <path
      fill="#fff"
      d="M1.42.96l10.4 10.32 2.84-2.85-12.6-7.27a1.38 1.38 0 00-.64-.2zm-.87.48c-.1.17-.15.36-.15.58v20.06c0 .16.03.3.09.43l10.63-10.56L.55 1.44zm14.98 7.49l-3.04 3.03 3.04 3.01 3.73-2.13c.52-.3.6-.7.6-.9-.01-.34-.23-.66-.59-.85l-3.73-2.17zm-3.71 3.7l-10.5 10.4a1.19 1.19 0 00.54-.16l8.71-5.04 4.1-2.35-2.87-2.85z"
    />
  </svg>
);

googlePlayStore.propTypes = {
  className: PropTypes.string,
};

module.exports = googlePlayStore;
