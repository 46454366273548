/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');
const logger = require('nordic/logger');
const createRestclient = require('nordic/restclient');
const SmsSender = require('../SmsSender');
const restclientConfig = require('../../../config/browser/restclient');
const validateWithCaptcha = require('../../utils/google-captcha');

const restclient = createRestclient(restclientConfig);
const AppContext = require('../../AppContext');

const SmsSenderWrapper = ({ section }) => {
  const { siteId } = React.useContext(AppContext);

  /* istanbul ignore next */
  const sendSms = (form) => {
    const params = {
      countryCode: form.countryCode,
      phoneNumber: form.phoneNumber,
      section,
    };

    window.melidata('send', 'event', {
      path: '/landing/home/click',
      event_data: {
        id: 'sms-button',
      },
    });

    return restclient
      .post('sms', { params })
      .then(() => ({ data: { success: true } }))
      .catch((error) => {
        logger.error(error.message, {
          action: 'sendSms',
        });
        return Promise.reject(error);
      });
  };

  const captchaExpectedAction = 'send_sms_home';

  /* istanbul ignore next */
  const validateAndSend = (form) =>
    validateWithCaptcha(captchaExpectedAction).then((isValid) => {
      if (isValid) return sendSms(form);
      return Promise.reject();
    });

  return (
    <SmsSender
      siteId={siteId}
      phoneValidations={{
        maxPhoneNumberLength: 11,
        minPhoneNumberLength: 10,
      }}
      type="slin"
      submitButtonSize="medium"
      onSend={validateAndSend}
    />
  );
};

SmsSenderWrapper.propTypes = {
  section: PropTypes.string.isRequired,
};

module.exports = SmsSenderWrapper;
