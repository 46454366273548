const React = require('react');
const PropTypes = require('prop-types');
const classnames = require('classnames');
const Button = require('../HubButton');
const AppStoreIcon = require('./icons/appStore');
const GooglePlayStoreIcon = require('./icons/googlePlayStore');
const ContextConfig = require('../../AppContext');

const deviceOSTypes = ['ios', 'android'];

const icons = {
  ios: AppStoreIcon,
  android: GooglePlayStoreIcon,
};

const renderIcon = (name, className) => {
  const RenderStoreIcon = icons[name];
  if (!RenderStoreIcon) return null;
  return <RenderStoreIcon className={className} />;
};

const renderButton = (deviceOS, text, keyText, href, data, className = '') => (
  <Button
    href={href}
    data={data}
    text={text[keyText]}
    className={classnames('sticky-button', className)}
  >
    {renderIcon(deviceOS, 'store-icon')}
  </Button>
);

const StickyButton = ({ text, callToAction }) => {
  const { deviceType, deviceOS } = React.useContext(ContextConfig);
  const { link, data } = callToAction;

  if (deviceType !== 'mobile') return null;

  return (
    <div className="sticky-button-wrapper">
      {deviceOSTypes.includes(deviceOS)
        ? renderButton(deviceOS, text, 'downloadApp', link, data)
        : [
            renderButton(
              'ios',
              text,
              'appStore',
              link,
              data,
              'sticky-button-first'
            ),
            renderButton('android', text, 'googleStore', link, data),
          ]}
    </div>
  );
};

StickyButton.propTypes = {
  text: PropTypes.shape({}).isRequired,
  callToAction: PropTypes.shape({
    data: PropTypes.shape({
      analytics: PropTypes.shape({
        category: PropTypes.string,
        action: PropTypes.string,
        label: PropTypes.string,
      }),
      melidata: PropTypes.shape({
        id: PropTypes.string,
      }),
    }),
    link: PropTypes.string,
  }).isRequired,
};

module.exports = StickyButton;
