const React = require('react');
const PropTypes = require('prop-types');
const { Image } = require('nordic/image');
const classNames = require('classnames');

const AppContext = require('../../AppContext');

const ImageWrapper = (props) => {
  const { deviceOutdated } = React.useContext(AppContext);
  const { className = '', ...restProps } = props;

  return (
    <Image
      aria-hidden
      className={classNames('image-wrapper', className)}
      outdated={deviceOutdated}
      {...restProps}
    />
  );
};

ImageWrapper.propTypes = {
  className: PropTypes.string,
};

module.exports = ImageWrapper;
