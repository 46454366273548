const React = require('react');
const PropTypes = require('prop-types');

const Pix = require('./pix');

const IconsObj = {
  pix: Pix,
};

const Icons = ({ className = '', name = '' }) => {
  if (!IconsObj[name]) return null;

  const Icon = IconsObj[name];

  return <Icon className={className} />;
};

Icons.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
};

module.exports = Icons;
