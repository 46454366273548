const numberIsValid = (number, validations) => {
  const hasNumber = number && number !== '';
  const isMinLengthValid =
    validations.minPhoneNumberLength !== '' &&
    number.length >= validations.minPhoneNumberLength;
  const isMaxLengthValid =
    validations.maxPhoneNumberLength !== '' &&
    number.length <= validations.maxPhoneNumberLength;

  return hasNumber && isMinLengthValid && isMaxLengthValid;
};

module.exports = numberIsValid;
