const React = require('react');
const PropTypes = require('prop-types');

const appStore = ({ className = '' }) => (
  <svg fill="none" viewBox="0 0 24 24" className={className}>
    <path
      fill="#fff"
      d="M18.32 12.75c-.03-3.04 2.48-4.5 2.6-4.57a5.6 5.6 0 00-4.4-2.37c-1.86-.2-3.64 1.1-4.6 1.1-.94 0-2.4-1.08-3.95-1.05a5.88 5.88 0 00-4.96 3c-2.12 3.68-.55 9.12 1.52 12.1 1 1.46 2.2 3.1 3.78 3.04 1.52-.06 2.1-.98 3.93-.98s2.36.98 3.97.95c1.63-.03 2.67-1.49 3.67-2.95 1.16-1.69 1.63-3.33 1.66-3.41-.04-.02-3.19-1.23-3.22-4.86zM15.3 3.83A5.29 5.29 0 0016.55 0C15.34.05 13.88.8 13 1.82a5.03 5.03 0 00-1.27 3.71c1.35.1 2.72-.69 3.56-1.7z"
    />
  </svg>
);

appStore.propTypes = {
  className: PropTypes.string,
};

module.exports = appStore;
