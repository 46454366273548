/* eslint-disable max-len */
const React = require('react');

const EnvelopeIcon = () => (
  <svg
    width="18"
    height="12"
    viewBox="0 0 20 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 0H0V14H20V0ZM18.6656 4.00168V12.5988H1.33222V4.00035L8.34491 8.21234L8.55297 8.32731C9.53721 8.82551 10.698 8.78718 11.6551 8.21234L18.6656 4.00168ZM18.6656 2.38882V1.39883H1.33222V2.38748L9.00694 6.99711C9.56627 7.33306 10.2416 7.3636 10.822 7.08873L10.9931 6.99711L18.6656 2.38882Z"
      fill="#6E6E6E"
    />
  </svg>
);

module.exports = EnvelopeIcon;
