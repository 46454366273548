/* eslint-disable nordic/i18n-jsx-no-literals */
const React = require('react');
const PropTypes = require('prop-types');
const classnames = require('classnames');
const SmsSenderWrapper = require('../SmsSenderWrapper');
const AppContext = require('../../AppContext');

const Hero = ({ title, description = '', smsLabel = '' }) => {
  const { deviceType } = React.useContext(AppContext);
  const verifyDevice = deviceType === 'desktop';

  return (
    <header className="hero">
      <div className="hero__background" />
      <div className="hero__text">
        <div className="hero__dots" />
        <div className={classnames('hero__headline')}>
          <h1 className="hero__headline-title">{title}</h1>
          {!!description && (
            <h2 className="hero__headline-description">{description}</h2>
          )}
          {smsLabel && verifyDevice ? (
            <div className="hero__headline-sms">
              <p className="hero__headline-sms-label">{smsLabel}</p>
              <div className="hero__headline-sms-sender">
                <SmsSenderWrapper section="hero" />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </header>
  );
};

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  smsLabel: PropTypes.string,
};

module.exports = Hero;
