/**
 * Module dependencies
 */
const React = require('react');
const { Script } = require('nordic/script');

const MeliGA = () => (
  <Script>
    {`
      function konamiCode(callback) {
        var kkeys = [];
        var konami = '38,38,40,40,37,39,37,39,66,65';
        return function (event) {
          kkeys.push(event.keyCode);
          if (kkeys.toString().indexOf(konami) >= 0) {
            callback();
            kkeys = [];
          }
        };
      }

      var handler = konamiCode(function () {
        var matches = document.querySelectorAll(".hub-card");
        matches.forEach(function(card) {
          card.classList.add("hub-card--animate");
        });
      });

      window.addEventListener('keydown', handler);
      `}
  </Script>
);

/**
 * MeliGA.
 */
module.exports = MeliGA;
